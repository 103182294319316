<template>
  <div
    class="bg lbg"
    style="width: 100%; height: 100%"
  >
    <!--    左边文字部分-->
    <div class="loginBg_left">
      <div class="dl_text">
        <img
          src="~@/assets/login_logo.png"
        >
      </div>
      <div class="welcome_dl_text">
        <span>欢迎使用</span><br>
        <span>「旭猫数字人」</span>
      </div>
      <div class="ai_text">
        AI改变生活，改变生活方式
      </div>
      <div class="no_ai">
        <span
          class="el-icon-caret-right"
          style="margin-left: -10px"
        ></span>
        <span>还没有登录账号？</span>
        <el-button
          v-if="!showRegister && !showReset"
          style="color: rgba(255, 110, 48, 1);background-color: transparent;border: none;font-size: 16px;"
          @click="showRegister = true "
        >
          立即注册
        </el-button>
        <el-button
          v-if="showRegister || showReset"
          style="color: rgba(255, 110, 48, 1);background-color: transparent;border: none;font-size: 16px;"
          @click="showRegister = false,showReset = false"
        >
          立即登陆
        </el-button>
      </div>
    </div>
    <!--    中间为部分渐变色文字图层下-->
    <div class="bg_color1"></div>
    <!--    右边登录区部分-->
    <div class="loginBg">
      <div class="bg_color2"></div>
      <!--      <div class="bg_color4"></div>-->
      <div
        v-show="!showRegister && !showReset"
        class="right"
      >
        <div class="title">
          <b v-if="!showExchange">登陆</b>
          <b v-if="showExchange">卡密兑换</b>
        </div>
        <div>
          <div class="email_all">
            <!--            账号登陆显示邮箱和卡密-->
            <div v-if="name_login_text && !mail_login_text && !showExchange ">
              <div class="btn_email_bg">
                <img
                  src="~@/assets/img/icon_email.png"
                  class="icon_email"
                >
                <el-button
                  style="background-color: transparent;border: none;color: white;font-size: 14px;"
                  @click="mail_login()"
                >
                  通过邮箱登陆</el-button>
              </div>
              <div class="btn_card_bg">
                <img
                  src="~@/assets/img/icon_card.png"
                  class="icon_card"
                >
                <el-button
                  style="background-color: transparent;border: none;color: white;font-size: 14px;"
                  @click="fun_kami()"
                >
                  通过卡密兑换</el-button>
              </div>
            </div>
            <!--            邮箱登陆显示账号和卡密-->
            <div v-if="!name_login_text && mail_login_text && !showExchange">
              <div class="btn_email_bg">
                <img
                  src="~@/assets/img/icon_username_login.png"
                  class="icon_email"
                >
                <el-button
                  style="background-color: transparent;border: none;color: white;font-size: 14px;"
                  @click="name_login()"
                >
                  通过账号登陆</el-button>
              </div>
              <div class="btn_card_bg">
                <img
                  src="~@/assets/img/icon_card.png"
                  class="icon_card"
                >
                <el-button
                  style="background-color: transparent;border: none;color: white;font-size: 14px;"
                  @click="fun_kami()"
                >
                  通过卡密兑换</el-button>
              </div>
            </div>
            <!--            卡密登陆显示邮箱和账号-->
            <div v-if="!name_login_text && !mail_login_text && showExchange">
              <div class="btn_email_bg">
                <img
                  src="~@/assets/img/icon_email.png"
                  class="icon_email"
                >
                <el-button
                  style="background-color: transparent;border: none;color: white;font-size: 14px;"
                  @click="mail_login()"
                >
                  通过邮箱登陆</el-button>
              </div>
              <div class="btn_card_bg">
                <img
                  src="~@/assets/img/icon_username_login.png"
                  class="icon_card"
                >
                <el-button
                  style="background-color: transparent;border: none;color: white;font-size: 14px;"
                  @click="name_login()"
                >
                  通过账户登录</el-button>
              </div>
            </div>
          </div>
          <div class="text_or"></div>
          <div v-if="name_login_text">
            <div class="mb30 username input-div">
              <el-input
                v-model="username"
                style="text-align: center"
                placeholder="请输入您的账号"
                size="medium"
                class="input-login"
              ></el-input>
            </div>
            <div class="mb20 input-div">
              <el-input
                v-model="password"
                placeholder="请输入您的密码"
                show-password
                class="input-login"
                @keyup.enter.native="login"
              ></el-input>
            </div>
          </div>
          <div v-if="mail_login_text">
            <div class="mb30 iptBox input-div">
              <el-input
                v-model="email"
                placeholder="请输入您的邮箱"
                size="medium"
                class="input-login"
              ></el-input>
            </div>
            <div
              class="mb20 iptBox"
              style="background: rgba(53, 65, 75, 1);"
            >
              <el-input
                v-model="code"
                placeholder="请输入您的验证码"
                class="input-login"
                @keyup.enter.native="login"
              >
                <template
                  slot="append"
                  class="get_code"
                >
                  <el-button
                    v-show="!codeShow"
                    type="primary"
                    @click="send"
                  >获取邮箱验证码</el-button>
                  <el-button
                    v-show="codeShow"
                    type="primary"
                    disabled
                  >{{ count }}秒后重试</el-button>
                </template>
              </el-input>
            </div>
          </div>
          <div v-show="showExchange">
            <div class="mb30 username input-div">
              <el-input
                v-model="username"
                placeholder="请输入您的账号"
                size="medium"
                class="input-login"
              ></el-input>
            </div>
            <div class="mb20">
              <el-input
                v-model="code"
                placeholder="请输入兑换码"
                class="input-login"
                @keyup.enter.native="exchange"
              ></el-input>
            </div>
          </div>
          <div class="dfc fs13">

          </div>

          <el-button
            v-if="!showExchange"
            type="primary"
            class="login"
            style=""
            size="medium"
            @click="login"
          >登录</el-button>
          <el-button
            v-if="showExchange"
            type="primary"
            class="login"
            style=""
            size="medium"
            @click="exchange"
          >兑换</el-button>
          <div class="chek">
            <el-checkbox v-model="checked">
              我已阅读理解并同意 <span
                style="color: rgba(255, 110, 48, 1); margin-left: 10px"
                @click="openDoc('yh')"
              >《用户协议》</span>和<span
                style="color: rgba(255, 110, 48, 1);"
                @click="openDoc('ys')"
              >《隐私协议》</span>
            </el-checkbox>
            <span
              class="cp"
              style="color: rgba(255, 255, 255, 1);margin-left: 30px"
              @click="showReset = true"
            >忘记密码？</span>
          </div>
        </div>
      </div>
      <Register
        v-show="showRegister"
        @close="close"
      ></Register>
      <resetPassword
        v-show="showReset"
        @close="close"
      ></resetPassword>
    </div>
    <!--   底部logo图片区-->
    <div class="show_desc_img">
      <div class="desc_all">
        <div
          class="ai_one"
          style="margin-left: 0px; margin-top: 1px"
        >
          <div class="one_img">


            <img src="~@/assets/img/ai_create.png">
          </div>
          <div class="img_text">
            AI创作
          </div>
        </div>
        <div
          class="ai_line"
          style="margin-left: 17px; margin-top: 1px"
        >
        </div>
        <div
          class="ai_two"
          style="margin-left: 42px; margin-top: 1px"
        >
          <div class="two_img">
            <img src="~@/assets/img/ai_draw.png">
          </div>
          <div class="img_text">
            AI绘画
          </div>
        </div>
        <div
          class="ai_line"
          style="margin-left: 17px; margin-top: 1px"
        ></div>
        <div
          class="ai_three"
          style="margin-left: 42px; margin-top: 1px"
        >
          <div class="three_img">
            <img src="~@/assets/img/ai_people.png">
          </div>
          <div class="img_text">
            AI数字人
          </div>
        </div>
      </div>
    </div>

    <!-- 控制 -->
    <div class="contrl">
      <div @click="handleMinus">
        <i class="el-icon-minus"></i>
      </div>
      <div @click="handleClose">
        <i class="el-icon-close"></i>
      </div>
    </div>
  </div>
</template>
<script>
import { login, getUser, use_cami, send_code } from "@/api/user.js";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import Register from "@/components/register.vue";
import resetPassword from "@/components/resetPassword.vue";
export default {
  components: {
    Register,
    resetPassword,
  },
  data () {
    return {
      username: "",
      password: "",
      email: "",
      code: "",
      activeName: "first",
      count: "",
      codeShow: false,
      timer: null,
      showRegister: false,
      showReset: false,
      checked: false,
      showExchange: false,
      name_login_text: true,
      mail_login_text: false
    };
  },
  mounted () {
    this.listenerClose();
  },
  methods: {
    listenerClose () {
      this.$ipc.invoke(ipcApiRoute.closeWin).then((res) => {
        console.log("关闭窗口");
      });
    },
    mail_login () {
      this.showExchange = false
      this.mail_login_text = true
      this.name_login_text = false
    },
    name_login () {
      this.mail_login_text = false
      this.name_login_text = true
      this.showExchange = false
    },
    fun_kami () {
      this.mail_login_text = false
      this.name_login_text = false
      this.showExchange = true
    },
    openDoc (type) {
      console.log(111);
      this.$ipc.invoke(ipcApiRoute.openPath, { fileName: type == "ys" ? "privacy.doc" : "user.doc" }).then((res) => {
        console.log("打开地址");
      });
    },
    async send () {
      if (!this.email) {
        this.$message.error("请输入邮箱");
        return;
      }
      let clicktime = new Date().getTime() + 60000; //未来60秒，这里也毫秒为单位
      sessionStorage.setItem("EndTime", JSON.stringify(clicktime)); //存入sessionStorage
      /* this.$message.success("发送成功");
      this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去 */

      let res = await send_code({
        email: this.email,
        event: 2,
      });
      console.log(res)
      if (res.code == 200) {
        this.$message.success("发送成功");
        this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去
      } else {
        this.$message.error(res.msg);
        console.log(this.$message.error(res.msg))
      }
    },
    timeDown (time) {
      if (!this.timer) {
        this.count = Math.ceil((JSON.parse(time) - new Date().getTime()) / 1000); //取出计时
        console.log(111, this.count);

        this.codeShow = true;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.codeShow = false;
            clearInterval(this.timer); //清除计时器
            this.timer = null;
            sessionStorage.removeItem("EndTime"); //计时完后清除sessionStorage
          }
        }, 1000);
      }
    },
    exchange () {
      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.code) {
        this.$message.error("卡密不能为空");
        return;
      }

      let params = {
        username: this.username,
        code: this.code,
      };
      use_cami(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.username = "";
          this.code = "";
          this.showExchange = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClose () {
      this.$ipc.invoke(ipcApiRoute.closeApp).then((r) => { });
    },
    handleMinus () {
      this.$ipc.invoke(ipcApiRoute.handleApp, "mini").then((r) => { });
    },
    login () {
      // localStorage.setItem('token', '111')
      // this.$router.push({ name: 'video' })

      if (!this.username) {
        this.$message.error("用户名不能为空");
        return;
      }
      if (!this.password) {
        this.$message.error("密码不能为空");
        return;
      }
      if (!this.checked) {
        this.$message.error("请勾选用户协议");
        return;
      }

      let params = {
        username: this.username,
        password: this.password,
      };
      login(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(res.msg);
          localStorage.setItem("token", res.data.token);
          getUser({ token: res.data.token }).then((res1) => {
            if (res1.code == "200") {
              localStorage.setItem("userInfo", JSON.stringify(res1.data));
              this.$router.push({ name: "home" });
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    close () {
      this.showRegister = false;
      this.showReset = false;
    },
  },
};
</script>
<style lang="less" scoped>
.el-input-group__append {
  position: absolute;
}
.input-div {
  background: rgba(53, 65, 75, 1);
  /*background: rgb(1 10 18 1);*/
  border-radius: 16px;
}
/deep/.el-input-group__append {
  position: absolute;
  margin: -2.75rem 19.25rem;
}
.input-login {
  /deep/ .el-input__inner {
    height: 64px;
    opacity: 0.4;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 24px 20px 24px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(110, 117, 124, 1);
  }
  /deep/ .el-input__inner::placeholder {
    color: rgba(110, 117, 124, 1);
  }
}
/deep/ .el-button--primary {
  background: linear-gradient(
    90deg,
    rgba(54, 243, 170, 1) 0%,
    rgba(4, 221, 181, 1) 100%
  );
}
.lbg {
  background: rgba(8, 1, 20, 1);
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  width: 1760px;
  height: 990px;
}
.loginBg_left {
  position: relative;
  width: 44rem;
  height: 544px;
  opacity: 1;
  margin-left:0rem;
  margin-top: 9rem;
  .dl_text {
    position: relative;
    float: left;
    align-items: center;
    width: 44rem;
    height: 100px;
    display: flex;
    img{
      margin: auto;
      width: 100px;
      height: 100px;
    }
  }
  .english_text {
    text-align: center;
    width: 13rem;
    height: 3rem;
    float: left;
    display: flex;
    margin: 5.125rem 0 0 -12.4rem;
    span {
      font-size: 12px;
      font-weight: 400;
      font-family: "Inter";
    }
  }
  .welcome_dl_text {
    position: relative;
    float: left;
    width: 44rem;
    height: 200px;
    opacity: 1;
    text-align: center;
    color: white;
    font-size: 62px;
    font-weight: 700;
    font-family: "思源黑体";
    line-height: 80px;
    span{
      text-align: center;
      display: inline-block;
      width: 100%;
    }
  }
  .ai_text {
    display: inline-block;
    position: relative;
    width: 44rem;
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(110, 117, 124, 1);
    font-family: Inter;
  }
  .no_ai {
    left: 0px;
    top: 0px;
    width: 355px;
    height: 56px;
    opacity: 1;
    border-radius: 16px;
    border: 2px solid rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    padding: 12px 24px 12px 24px;
    margin: auto;
    span {
      margin-left: 10px;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.bg_color1 {
  position: absolute;
  z-index: 0;
  left: 404px;
  top: 145px;
  width: 628px;
  height: 32.25rem;
  opacity: 0.3;
  background: linear-gradient(
    90deg,
    rgba(25, 78, 148, 1) 0%,
    rgba(116, 32, 133, 1) 100%
  );
  filter: blur(5.39125rem);
}
.loginBg {
  width: 800px;
  height: 650px;
  background: rgba(44, 53, 61, 1);
  border-radius: 24px 24px 0px 0px;
  -webkit-box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.07);
  position: relative;
  margin-left: 100px;
  margin-top: -75px;
  align-content: center;
  z-index: 10;
  overflow: hidden;

  .bg_color2 {
    margin-left: -300px;
    margin-top: -500px;
    width: 560px;
    height: 560px;
    mix-blend-mode: overlay;
    opacity: 1;
    background: linear-gradient(
      135.15deg,
      rgba(174, 128, 220, 1) 0%,
      rgba(220, 131, 195, 1) 47.77%,
      rgba(128, 132, 220, 1) 100%
    );
    filter: blur(5.39125rem);
  }
  .bg_color3 {
    width: 340px;
    height: 340px;
    border-radius: 50%;
    margin-left: 200px;
    margin-top: 180px;
    mix-blend-mode: overlay;
    opacity: 1;
    background: linear-gradient(
      135.15deg,
      rgba(174, 128, 220, 1) 0%,
      rgba(220, 131, 195, 1) 47.77%,
      rgba(128, 132, 220, 1) 100%
    );
    filter: blur(5.39125rem);
  }
  .bg_color4 {
    width: 389px;
    height: 389px;
    margin-left: 29rem;
    margin-top: 2.25rem;
    mix-blend-mode: overlay;
    border-radius: 50%;
    opacity: 1;
    background: linear-gradient(
      135.15deg,
      rgba(174, 128, 220, 1) 0%,
      rgba(220, 131, 195, 1) 47.77%,
      rgba(128, 132, 220, 1) 100%
    );
    filter: blur(5.39125rem);
  }
  .login {
    width: 100%;
    height: 56px;
    border-radius: 16px;
    margin: 25px 0px 30px 0px;
    background: linear-gradient(
      90deg,
      rgba(54, 243, 170, 1) 0%,
      rgba(4, 221, 181, 1) 100%
    );
  }

  .right {
    width: 459px;
    height: 545px;
    position: absolute;
    top: 0;
    text-align: left;
    font-size: 15px;
    margin-left: 22%;
    margin-top: 10%;

    .title {
      font-size: 48px;
      font-weight: 700;
      margin-top: -10%;
      text-align: center;
      font-family: "Poppins";
      color: white;
    }
    .text_or {
      margin-left: 0px;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 453px;
      height: 24px;
      opacity: 1;
      /** 文本1 */
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 24px;
      color: rgba(106, 121, 134, 1);
      text-align: center;
      vertical-align: top;
    }
    .email_all {
      margin-top: 10%;
      height: 4.6rem;
    }
    .btn_email_bg {
      width: 12.3rem;
      height: 4.5rem;
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 16px;
      float: left;
      display: flex;
      padding: 16px 20px 16px 20px;
    }
    .icon_email .icon_card {
      width: 27px;
      height: 27px;
    }

    .btn_card_bg {
      width: 12.3rem;
      height: 4.5rem;
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 16px;
      float: right;
      display: flex;
      padding: 16px 20px 16px 20px;
    }
  }
}
.show_desc_img {
  padding-top: 48px;
  padding-bottom: 52px;
  padding-left: 46px;
  position: absolute;
  z-index: 1;
  width: 800px;
  height: 180px;
  border-radius: 0px 0px 24px 24px;
  background: linear-gradient(
    90deg,
    rgba(36, 57, 73, 1) 0%,
    rgba(28, 24, 64, 1) 100%
  );
  margin-left: 50.21rem;
  margin-top: 45rem;
  .desc_all {
    width: 704px;
    height: 100px;
    opacity: 1;
    display: flex;
    position: relative;
    float: left;
    opacity: 1;
    justify-content: left;
  }
  .ai_one,
  .ai_two,
  .ai_three {
    width: 180px;
    height: 98px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    float: left;
  }
  .ai_line {
    margin-top: 0px;
    width: 2px;
    height: 100px;
    opacity: 0.05;
    display: block;
    float: left;
    background: rgba(255, 255, 255, 1);
  }
  .one_img,
  .two_img,
  .three_img {
    width: 64px;
    height: 64px;
    img {
      width: 41px;
      height: 47px;
    }
  }
  .img_text {
    width: 180px;
    height: 20px;
    line-height: 24px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    vertical-align: top;
    margin-top: 10px;
  }
}

.exchangeBox {
  color: #409eff;
  text-align: right;
  position: relative;
  top: 30px;
  font-size: 14px;
  width: 80px;
  left: 240px;
  z-index: 999;
}

::v-deep {
  .el-input__inner {
    background: #f7f7f7 !important;
    border-radius: 6px !important;
    border: 0 !important;
  }
  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
.contrl {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 20px;
  justify-content: end;
  position: absolute;
  right: 0;
  width: 100vw;
  left: 0;
  top: 0;
  background: hsla(0, 0%, 100%, 0.5);
  backdrop-filter: blur(5px);
  -webkit-app-region: drag;

  div {
    width: 30px;
    line-height: 28px;
    -webkit-app-region: no-drag;
  }
}
</style>
