<template>
  <div class="register">
    <div class="title" >
          <b>注册</b>
    </div>
    <div class="mt20">
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的账号"  v-model="username" class="input-register"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的密码"  show-password v-model="password" class="input-register"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请确认您的密码"  show-password v-model="passwords" class="input-register"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的手机号"  v-model="phone" class="input-register"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的邮箱"  v-model="email" class="input-register"></el-input>
      </div>
      <div class="mb15 iptBox">
        <el-input placeholder="请输入您的验证码"  @keyup.enter.native="register" v-model="code" class="input-register">
          <template slot="append">
            <el-button style="position: absolute;margin: -18px -22px;padding-bottom: 16px; padding-top: 10px" v-show="!codeShow" type="primary" @click="send">获取邮箱验证码</el-button>
            <el-button style="position: absolute;margin: -18px -22px;padding-bottom: 16px; padding-top: 10px" v-show="codeShow" type="primary" disabled>{{ count }}秒后重试</el-button>
          </template>
        </el-input>
      </div>
    </div>
    <el-button style="width: 100%" type="primary" class="login" @click="register">注册</el-button>
  </div>
</template>
<script>
import { send_code, register } from "@/api/user.js";

export default {
  name: "register",
  props: {
    text: {
      type: String,
      default: "内容",
    },
  },
  data() {
    return {
      username: "",
      password: "",
      passwords: "",
      email: "",
      phone: "",
      count: "",
      code: "",
      codeShow: false,
      timer: null,
    };
  },
  mounted() {},
  methods: {
    async send() {
      if (!this.email) {
        this.$message.error("请输入邮箱");
        return;
      }
      let clicktime = new Date().getTime() + 60000; //未来60秒，这里也毫秒为单位
      sessionStorage.setItem("EndTime", JSON.stringify(clicktime)); //存入sessionStorage
      // this.$message.success("发送成功");
      // this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去

      let res = await send_code({
        email: this.email,
        event: 1,
      });
      if (res.code == 200) {
        this.$message.success("发送成功");
        this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去
      } else {
        this.$message.error(res.msg);
      }
    },
    timeDown(time) {
      if (!this.timer) {
        this.count = Math.ceil((JSON.parse(time) - new Date().getTime()) / 1000); //取出计时
        console.log(111, this.count);

        this.codeShow = true;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.codeShow = false;
            clearInterval(this.timer); //清除计时器
            this.timer = null;
            sessionStorage.removeItem("EndTime"); //计时完后清除sessionStorage
          }
        }, 1000);
      }
    },
    async register() {
      let params = {
        username: this.username,
        password: this.password,
        passwords: this.passwords,
        email: this.email,
        phone: this.phone,
        code: this.code,
      };
      let res = await register(params);
      if (res.code == "200") {
        this.$message.success(res.msg);
        setTimeout(() => {
          this.goBack();
        }, 700);
      } else {
        this.$message.error(res.msg);
      }
    },
    goBack() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  font-size: 15px;
  margin-top: -6.5%;
  margin-left: 11rem;
  width: 453px;

  .login {
    width: 90%;
    background: #3370ff;
    margin-top: -180px;
    height: 45px;
    font-size: 16px;
  }

  .title {
      font-size: 48px;
      font-weight: 700;
      margin-top: -10%;
      text-align: center;
      font-family: "Poppins";
      color: white;
  }
  .tips {
    margin: 5px 0 15px;
    font-family: MiSans, MiSans;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    line-height: 27px;
  }

  .chek {
    margin: 0 auto;
  }
}
.input-div{
  background: rgba(53, 65, 75, 1);
  /*background: rgb(1 10 18 1);*/
  border-radius: 16px
}
/deep/.el-input__inner{

}
::v-deep {
  .el-input__inner {
    height: 44px;
    text-align: center;
    border-radius: 16px !important;
    border: 0 !important;
    margin-bottom: 20px;
    font-size: 12px;
  }
  .el-input__inner {
    opacity: 0.4;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 24px 20px 24px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(110, 117, 124, 1);
  }
  .el-input__inner::placeholder{
    font-size: 12px;
    color: rgba(110, 117, 124, 1);
  }
  .el-button--primary{
    background: rgba(54, 243, 170, 1);
  }
  // .el-input__icon {
  //   width: 35px;
  //   line-height: 45px;
  //   font-size: 16px;
  //   font-weight: bold;
  // }
.input-register {
  /deep/.el-input__inner {
    opacity: 0.4;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 24px 20px 24px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(110, 117, 124, 1);
  }
}
  .el-checkbox__label {
    font-size: 13px;
  }

  .el-tabs__item.is-active,
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #3370ff;
  }
  .el-tabs__active-bar {
    background: #3370ff;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #3370ff;
    border-color: #3370ff;
  }
  .el-button--primary {
    color: #fff !important;
  }
  .el-tabs__item {
    font-size: 16px;
  }
  .el-input-group__append {
    border: 0;
  }

  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
