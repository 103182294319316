<template>
  <div class="find_pass">
     <div class="title" >
          <b>找回密码</b>
    </div>
    <div class="mt30">
      <div class="mb15 iptBox input-div">
        <el-input placeholder="请输入您的密码"  show-password v-model="password"></el-input>
      </div>
      <div class="mb15 iptBox input-div">
        <el-input placeholder="请确认您的密码"  show-password v-model="passwords"></el-input>
      </div>
      <div class="mb15 iptBox input-div">
        <el-input placeholder="请输入您的邮箱"  v-model="email"></el-input>
      </div>
      <div class="mb15 iptBox input-div">
        <el-input placeholder="请输入您的验证码"  @keyup.enter.native="find_pass" v-model="code">
          <template slot="append">
            <el-button style="position: absolute;margin: -18px -22px;padding-bottom: 16px; padding-top: 10px"  v-show="!codeShow" type="primary" @click="send">获取邮箱验证码</el-button>
            <el-button style="position: absolute;margin: -18px -22px;padding-bottom: 16px; padding-top: 10px"  v-show="codeShow" type="primary" disabled>{{ count }}秒后重试</el-button>
          </template>
        </el-input>
      </div>
    </div>

    <el-button style="width: 100%; margin-left: 0px" type="primary" class="login" @click="find_pass">确认</el-button>
  </div>
</template>
<script>
import { send_code, find_pass } from "@/api/user.js";

export default {
  name: "resetPassword",
  props: {
    text: {
      type: String,
      default: "内容",
    },
  },
  data() {
    return {
      password: "",
      passwords: "",
      email: "",
      count: "",
      code: "",
      codeShow: false,
      timer: null,
    };
  },
  mounted() {},
  methods: {
    async send() {
      if (!this.email) {
        this.$message.error("请输入邮箱");
        return;
      }
      let clicktime = new Date().getTime() + 60000; //未来60秒，这里也毫秒为单位
      sessionStorage.setItem("EndTime", JSON.stringify(clicktime)); //存入sessionStorage

      let res = await send_code({
        email: this.email,
        event: 3,
      });
      if (res.code == 200) {
        this.$message.success("发送成功");
        this.timeDown(clicktime); //请求成功则触发timeDown并将时间携带过去
      } else {
        this.$message.error(res.msg);
      }
    },
    timeDown(time) {
      if (!this.timer) {
        this.count = Math.ceil((JSON.parse(time) - new Date().getTime()) / 1000); //取出计时
        console.log(111, this.count);

        this.codeShow = true;
        this.timer = setInterval(() => {
          if (this.count > 0) {
            this.count--;
          } else {
            this.codeShow = false;
            clearInterval(this.timer); //清除计时器
            this.timer = null;
            sessionStorage.removeItem("EndTime"); //计时完后清除sessionStorage
          }
        }, 1000);
      }
    },
    async find_pass() {
      let params = {
        password: this.password,
        passwords: this.passwords,
        email: this.email,
        code: this.code,
      };
      let res = await find_pass(params);
      if (res.code == "200") {
        this.$message.success(res.msg);
        setTimeout(() => {
          this.goBack();
        }, 700);
      } else {
        this.$message.error(res.msg);
      }
    },
    goBack() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
.find_pass {
  font-size: 15px;
  /*margin-top: -30%;*/
  margin-top: 8%;
  margin-left: 11rem;
  width: 453px;
  .login {
    width: 90%;
    background: #3370ff;
    margin: 25px 5% 15px;
    height: 45px;
    font-size: 16px;
  }
  .title {
      font-size: 48px;
      font-weight: 700;
      margin-top: -10%;
      text-align: center;
      font-family: "Poppins";
      color: white;
  }
  /*.input-div{*/
  /*background: rgba(53, 65, 75, 1);*/
  /*!*background: rgb(1 10 18 1);*!*/
  /*border-radius: 16px*/
  /*}*/
  ::v-deep {
    .el-input__inner  {
    height: 44px;
    text-align: center;
    border: 0 !important;
    margin-bottom: 20px;
    font-size: 12px;
  }
    .el-input__inner {
    opacity: 0.4;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 24px 20px 24px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(110, 117, 124, 1);
  }
    .el-input__inner::placeholder{
    font-size: 12px;
    color: rgba(110, 117, 124, 1);
  }
    .el-button--primary{
      background: rgba(54, 243, 170, 1);
    }
  }
  .tips {
    margin: 5px 0 15px;
    font-family: MiSans, MiSans;
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    line-height: 27px;
  }

  .chek {
    margin: 0 auto;
  }
}

::v-deep {
  .el-input__inner {
    background: #f7f7f7 !important;
    border-radius: 6px !important;
    border: 0 !important;
  }
  // .el-input__icon {
  //   width: 35px;
  //   line-height: 45px;
  //   font-size: 16px;
  //   font-weight: bold;
  // }

  .el-checkbox__label {
    font-size: 13px;
  }

  .el-tabs__item.is-active,
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #3370ff;
  }
  .el-tabs__active-bar {
    background: #3370ff;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background: #3370ff;
    border-color: #3370ff;
  }
  .el-button--primary {
    color: #fff !important;
  }
  .el-tabs__item {
    font-size: 16px;
  }
  .el-input-group__append {
    border: 0;
  }

  p {
    max-width: 100%;
    text-align: left;
    text-indent: 24px;
    font-size: 16px;
  }

  .text {
    padding: 30px;
    padding-top: 0px;
  }
}
</style>
